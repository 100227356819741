import {configureStore} from '@reduxjs/toolkit';

// Import redux reducers
import AuthReducers from './auth/sliceReducer';
import UiReducers from './ui/sliceReducer';
import UsersReducer from './users/slice';

// Initialize Redux Store
const store = configureStore({
  reducer: {
    auth: AuthReducers,
    ui: UiReducers,
    users: UsersReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    // Turn off serializable check in develepment mode to prevent console warning and slowdown when state is very large
    // https://redux-toolkit.js.org/api/serializabilityMiddleware
    serializableCheck: {
      ignoredActions: ['modal/setModal'],
      ignoredPaths: ['ui.modal.item'],
    },
  }),
});

export default store;
