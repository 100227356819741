import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  allUsers: null,
  pending: true,
  touched: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
  },
});

export const userActions = usersSlice.actions;

export default usersSlice.reducer;
